
import { Component, Vue, PropSync } from 'vue-property-decorator'

@Component
export default class Accordion extends Vue {
  @PropSync('opened', { required: false }) openedSync!: boolean;

  internalOpen = false

  private toggle (): void {
    this.openedSync = !this.openedSync
    this.internalOpen = !this.internalOpen
  }
}
