
import Vue from 'vue'
import NeoSidebar from '@/components/Sidebar.vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default Vue.extend({
  name: 'app',
  components: {
    NeoSidebar
  },
  data: () => ({
    themes: ['light', 'dark']
  }),
  mounted: function () {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      const root = document.querySelector('html')

      root?.classList.add('dark')
    }
  },
  methods: {
    toggleTheme (): void {
      const root = document.querySelector('html')

      root?.classList.toggle('dark')
    }
  }
})
