
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import NeoIconAccordion from '@/components/IconAccordion.vue'

@Component({
  components: {
    NeoIconAccordion
  }
})
export default class NavAccordion extends Vue {
  @Prop({ default: 'UNTITLED' }) title!: string;
  @PropSync('opened', { required: false }) openedSync!: boolean;
}
