import { render, staticRenderFns } from "./SidebarMenuItemL1.vue?vue&type=template&id=36940f44"
import script from "./SidebarMenuItemL1.vue?vue&type=script&lang=ts"
export * from "./SidebarMenuItemL1.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_cache-loader@4.1.0_webpack@5.92.1__css-loader@5.2_z5mz3j7m6k2jb5i5pvg5x5jaty/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports