
import axios from 'axios'
import { Component, Vue, Watch } from 'vue-property-decorator'
import NeoConvarLine from '@/components/ConvarLine.vue'
import NeoParamInfoBox from '@/components/ParamInfoBox.vue'
import NeoParamWarnBox from '@/components/ParamWarnBox.vue'
import NeoSpinner from '@/components/Spinner.vue'
import NeoParamFlag from '@/components/ParamFlag.vue'

interface ConvarData {
  name?: string,
  realm?: string,
  data?: {
    helptext?: string,
    flags?: string,
    value: string
  },
  source?: {
    file: string,
    line: number
  },
  params?: {
    desc?:
      {
        text: string
      }[],
    note?:
      {
        text: string
      }[],
    internal?: boolean,
    predicted?: boolean,
    '2D'?: boolean,
    '3D'?: boolean,
    deprecated?: boolean,
    important?: boolean,
    return?:
      {
        description?: string,
        typs?: Array<string>
      }[]
  }
}

@Component({
  components: {
    NeoConvarLine,
    NeoParamInfoBox,
    NeoParamWarnBox,
    NeoSpinner,
    NeoParamFlag
  }
})
export default class ConvarDetails extends Vue {
  content: ConvarData = {}

  isLoading = true

  created (): void {
    this.fetchData()
  }

  @Watch('$route')
  fetchData (): void {
    this.isLoading = true

    axios.get('/data/' + this.dataLink + '.json')
      .then(response => {
        this.content = response.data
      })
      .catch(reason => {
        this.$notify({
          title: 'Could not fetch data for the function',
          text: reason,
          type: 'error'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  get hasHelptext (): boolean {
    return this.content?.data?.helptext !== undefined
  }

  get hasDescription (): boolean {
    return this.content?.params?.desc !== undefined && this.content?.params?.desc?.length !== 0
  }

  get hasNote (): boolean {
    return this.content?.params?.note !== undefined && this.content?.params?.note?.length !== 0
  }

  get isInternal (): boolean {
    return this.content?.params?.internal !== undefined
  }

  get is2D (): boolean {
    return this.content?.params !== undefined && this.content?.params['2D'] !== undefined
  }

  get is3D (): boolean {
    return this.content?.params !== undefined && this.content?.params['3D'] !== undefined
  }

  get isDeprecated (): boolean {
    return this.content?.params?.deprecated !== undefined
  }

  get isImportant (): boolean {
    return this.content?.params?.important !== undefined
  }

  get isPredicted (): boolean {
    return this.content?.params?.predicted !== undefined
  }

  get dataLink (): string {
    let lnk = 'createconvar/'

    if (this.$route.params.objectName !== undefined) lnk += this.$route.params.objectName.toLowerCase() + '/'
    lnk += this.$route.params.sectionName.toLowerCase() + '/'
    lnk += this.$route.params.realmName.toLowerCase() + '/'
    lnk += this.$route.params.elementName.replace(':', '..').replace(/[^\w.]/gi, '')

    return lnk
  }
}
