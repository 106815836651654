
import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import NeoSpinner from '@/components/Spinner.vue'
import NeoAccordion from '@/components/Accordion.vue'
import NeoRealmInfoRouterLink from '@/components/RealmInfoRouterLink.vue'
import NeoMenuItemL1 from '@/components/SidebarMenuItemL1.vue'

@Component({
  components: {
    NeoSpinner,
    NeoAccordion,
    NeoRealmInfoRouterLink,
    NeoMenuItemL1
  }
})
export default class Sidebar extends Vue {
  @Prop({ default: '' }) private query!: string;

  content: {
    module: unknown
    class: unknown
    hook: unknown
    createconvar: unknown
  } = {
    module: null,
    class: null,
    hook: null,
    createconvar: null
  }

  isLoading = true

  created (): void {
    this.isLoading = true

    axios.get('/data/overview.json')
      .then(response => {
        this.content = Object.freeze(response.data)
      })
      .catch(reason => {
        this.$notify({
          title: 'Could not fetch data for the sidebar',
          text: reason,
          type: 'error'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  get moduleOpened (): boolean {
    return this.$route.params.baseName === 'module'
  }

  get classOpened (): boolean {
    return this.$route.params.baseName === 'class'
  }

  get hookOpened (): boolean {
    return this.$route.params.baseName === 'hook'
  }

  get convarOpened (): boolean {
    return this.$route.params.baseName === 'createconvar'
  }
}
