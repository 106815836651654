<template>
  <div>
    Select an element on the left!
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
