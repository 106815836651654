
import { Component, Vue } from 'vue-property-decorator'
import NeoConvarDetails from './ConvarDetails.vue'
import NeoFunctionDetails from './FunctionDetails.vue'

@Component({
  components: {
    NeoConvarDetails,
    NeoFunctionDetails
  }
})
export default class Details extends Vue {
  name = 'Details'
}
