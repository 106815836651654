
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import NeoAccordion from '@/components/Accordion.vue'

@Component({
  components: {
    NeoAccordion
  }
})
export default class IconAccordion extends Vue {
  @Prop({ default: 'UNTITLED' }) title!: string;
  @Prop({ default: '' }) titleClasses!: string;
  @PropSync('opened', { required: false }) openedSync!: boolean;
}
