
import { Component, Prop, Vue } from 'vue-property-decorator'
import NeoRealmIndicator from '@/components/RealmIndicator.vue'

@Component({
  components: {
    NeoRealmIndicator
  }
})
export default class HeadLine extends Vue {
  @Prop({ default: '' }) realm!: string;
  @Prop({ default: () => ({}) }) source!: {
    file: string,
    line: number
  };
}
