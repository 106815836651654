
import { Component, Prop, Vue } from 'vue-property-decorator'
import NeoAccordion from '@/components/Accordion.vue'
import NeoSidebarMenuItemL2 from '@/components/SidebarMenuItemL2.vue'
import NeoSidebarMenuItemL3 from '@/components/SidebarMenuItemL3.vue'

@Component({
  components: {
    NeoAccordion,
    NeoSidebarMenuItemL2,
    NeoSidebarMenuItemL3
  }
})
export default class SidebarMenuItemL1 extends Vue {
  @Prop({ default: 'link' }) icon!: string;
  @Prop({ default: 'UNTITLED' }) title!: string;
  @Prop({ default: '' }) baseName!: string;
  @Prop({ default: () => ({}) }) elements!: Record<string, unknown>;
  @Prop({ default: false }) defaultOpened!: boolean;

  opened = this.defaultOpened

  isL3ChildOpen (sectionName: string): boolean {
    return this.defaultOpened && this.$route.params.sectionName === sectionName
  }

  isL2ChildOpen (objectName: string): boolean {
    return this.defaultOpened && this.$route.params.objectName === objectName
  }
}
