
import { Component, Prop, Vue } from 'vue-property-decorator'
import ParamBox from '@/components/ParamBox.vue'

@Component({
  components: {
    ParamBox
  }
})
export default class ParamWarnBox extends Vue {
  @Prop({ default: 'UNTITLED' }) title!: string;
  @Prop({ default: 'exclamation-triangle' }) icon!: string;
}
