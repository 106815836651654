
import { Component, Prop, Vue } from 'vue-property-decorator'

enum Realm {
  Client = 'client',
  Server = 'server',
  Shared = 'shared'
}

@Component
export default class RealmIndicator extends Vue {
  @Prop({ default: Realm.Shared }) realm!: Realm;

  get realmColor (): string {
    switch (this.realm) {
      case Realm.Client:
        return 'bg-realm-client'
      case Realm.Server:
        return 'bg-realm-server'
      default:
        return 'bg-realm-shared'
    }
  }
}
