import { render, staticRenderFns } from "./RealmIndicator.vue?vue&type=template&id=71cb872c&scoped=true"
import script from "./RealmIndicator.vue?vue&type=script&lang=ts"
export * from "./RealmIndicator.vue?vue&type=script&lang=ts"
import style0 from "./RealmIndicator.vue?vue&type=style&index=0&id=71cb872c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_cache-loader@4.1.0_webpack@5.92.1__css-loader@5.2_z5mz3j7m6k2jb5i5pvg5x5jaty/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cb872c",
  null
  
)

export default component.exports