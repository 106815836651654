
import axios from 'axios'
import { Component, Vue, Watch } from 'vue-property-decorator'

import NeoRealmInfoRouterLink from '@/components/RealmInfoRouterLink.vue'
import NeoParamInfoBox from '@/components/ParamInfoBox.vue'
import NeoSpinner from '@/components/Spinner.vue'
import NeoIconAccordion from '@/components/IconAccordion.vue'

interface ObjectData {
  name: string,
  authors?: string[],
  sections?: Record<string, Record<string, unknown>>
}

@Component({
  components: {
    NeoParamInfoBox,
    NeoRealmInfoRouterLink,
    NeoSpinner,
    NeoIconAccordion
  }
})
export default class ObjectDetails extends Vue {
  content: ObjectData = {
    name: 'UNKNOWN'
  }

  isLoading = true

  created (): void {
    this.fetchData()
  }

  @Watch('$route')
  fetchData (): void {
    this.isLoading = true

    axios.get('/data/' + this.dataLink)
      .then(response => {
        this.content = response.data.data
      })
      .catch(reason => {
        this.$notify({
          title: 'Could not fetch data for the object. Please retry later!',
          text: reason,
          type: 'error'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  get hasAuthors (): boolean {
    return this.content?.authors !== undefined
  }

  get hasSections (): boolean {
    return this.content?.sections !== undefined
  }

  sectionLink (sectionName: string): string {
    return this.$route.params.objectName + '/' + sectionName
  }

  get dataLink (): string {
    const lnk = this.$route.params.baseName.toLowerCase() + '/' + this.$route.params.objectName.toLowerCase() + '/detail.json'

    return lnk
  }
}
